import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Grid, Row, Col } from "react-flexbox-grid"
import style from "./header.module.css"
import logo from "../images/logo.jpg"

const Header = ({ siteTitle, isMargin }) => (
  <header className={style.header}>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `0.5rem 1.0875rem 0.5rem`,
        marginBottom: !isMargin ? "3rem" : "",
      }}
    >
      <Grid>
        <Row>
          <Col>
            <nav className={style.nav}>
              <ul>
                <li className={style.logo}>
                  <Link
                    to="/"
                    style={{
                      textDecoration: `none`,
                    }}
                  >
                    <img src={logo} alt="Logo" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about-us"
                    style={{
                      textDecoration: `none`,
                    }}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blog/1"
                    style={{
                      textDecoration: `none`,
                    }}
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to="/podcast/1"
                    style={{
                      textDecoration: `none`,
                    }}
                  >
                    Podcast
                  </Link>
                </li>
                <li>
                  <Link
                    to="/membership-info"
                    style={{
                      textDecoration: `none`,
                    }}
                  >
                    Membership Info
                  </Link>
                </li>
                <li style={{marginLeft: 'auto'}}>
                  <a href="https://app.dietrioters.com/login">
                    Login
                  </a>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Grid>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
